import React, { useState } from "react";
import { useItems } from "../app/browsersSlice";
import { useCandidates } from "../app/candidatesSlice";
import { buildTree, mapTree, convert, formatAmount } from "../lib/misc";
import { groupBy, sum, min, max, orderBy, first } from "lodash";
import { format, startOfToday, addMonths, startOfMonth, addDays } from "date-fns";
import { Common } from "@lainco/react-toolbox";
import Filter, { ButtonOptionFilter, SelectOptionFilter, GroupByFilter } from "../components/v2/Filter";
import { buildOptions } from "../lib/misc";
import { ScrollableTable } from "../components/ScrollableTable";
import {
  useAccountManagerFilter,
  useAdjustmentClauseFilter,
  useBeneficiaryFilter,
  useCategoryFilter,
  useCompanyFilter,
  useCurrencyFilter,
  useCustomerFilter,
  useFilter,
  useFlagsFilter,
  useProjectLeaderFilter,
} from "./SellOrderMilestonesPage";
import styled from "styled-components";
import { outcomes, bank_balance } from "./IncomesResumePage.data";

const useFilterOn = ({ title, name, state, filter, type, options, optionsLoading, fields }) => {
  const component =
    type === "button" ? (
      <ButtonOptionFilter header={title} name={name} options={options} optionsLoading={optionsLoading} />
    ) : type === "groupBy" ? (
      <GroupByFilter header={title} name={name} options={options} fields={fields} />
    ) : (
      <SelectOptionFilter header={title} name={name} options={options} optionsLoading={optionsLoading} />
    );
  return { state: { [name]: state }, filter, component };
};

const fieldProjectors = {
  category: (x) => x.sellOrder.category,
  project: (x) => x.sellOrder.project ?? { name: "NO PROJECT" },
  status: (x) => x.status,
  company: (x) => x.sellOrder.company,
  beneficiary: (x) => x.sellOrder.beneficiary,
  customer: (x) => x.sellOrder.customer,
  country: (x) => x.sellOrder.customer.country,
  useAccountManage: (x) => x.sellOrder.accountManager,
  projectLeader: (x) => x.sellOrder.projectLeader,
  currency: (x) => x.sellOrder.amount.currency,
  adjustmentClause: (x) => x.sellOrder.adjustmentClause,
};

const fields = [
  { code: "category", name: "Category" },
  { code: "project", name: "Product" },
  { code: "status", name: "Status" },
  { code: "company", name: "Company" },
  { code: "beneficiary", name: "Beneficiary" },
  { code: "customer", name: "Customer" },
  { code: "country", name: "Country" },
  { code: "accountManager", name: "Account Manager" },
  { code: "projectLeader", name: "Project Leader" },
  { code: "currency", name: "Currency" },
  { code: "adjustmentClause", name: "Adjustment Clause" },
];

const leaf = (node, ars, usd, prices, getDate) => {
  const date = getDate(node);
  return {
    key: getColumn(date, node.status).code,
    value: convert(date, node?.sellOrder?.amount?.currency, node?.getAmount(), ars, usd, prices),
  };
};

const groupLeafsAndSum = (items) => ({
  ...Object.fromEntries([...Object.entries(groupBy(items, (x) => x.key))].map((x) => [x[0], sum(x[1].map((y) => y.value))])),
  _total: sum(items.map((x) => x.value)),
});

const groupBranchsAndSum = (items) =>
  Object.fromEntries(
    Object.entries(groupBy(items.map((x) => Object.entries(x.values)).flat(), (x) => x[0])).map((x) => [x[0], sum(x[1].map((y) => y[1]))])
  );

const branch = (node, items, level, depth) => {
  if (depth === 1) return { key: node.key, values: groupLeafsAndSum(items), isLeaf: true };
  else return { ...node, items, values: groupBranchsAndSum(items), isLeaf: false };
};

const flatten = (node, level = 0) =>
  node.items ? [{ level, ...node }, ...node.items.map((x) => flatten(x, level + 1)).flat()] : [{ level, ...node }];

const getColumn = (date, status) =>
  startOfMonth(date).getTime() === startOfMonth(startOfToday()).getTime()
    ? {
        code: format(date, "yyyyMM") + "_" + (status === "settled" ? "settled" : "unsettled"),
        name: format(date, "MMM yy") + " " + (status === "settled" ? " Settled" : " Pending"),
      }
    : { code: format(date, "yyyyMM"), name: format(date, "MMM yy") };

export default function IncomesResumePage() {
  const start = startOfMonth(addDays(startOfToday(), -7)).getTime();
  const [items, itemsLoading] = useItems("sellOrderMilestones");
  const [prices] = useCandidates("prices");
  const [currencies] = useCandidates("currencies");
  const ranges = buildOptions(["name"], ["Current", "Historic"]);
  const dates = buildOptions(["name"], ["Exp. Ok", "Real Ok", "Settlement", "Settlment - 5"]);

  const ars = currencies?.find((c) => c.code === "ARS");
  const usd = currencies?.find((c) => c.code === "USD");
  const {
    initialState: row1InitialState,
    filterComponents: row1FilterComponents,
    filterFunction: row1FilterFunction,
  } = useFilter(
    useFilterOn({
      title: "Range",
      name: "range",
      state: 1,
      filter: (filter, item) => filter.range === 2 || getDate(item)?.getTime() >= start,
      type: "button",
      options: ranges,
      optionsLoading: false,
    }),
    useCompanyFilter("button"),
    useBeneficiaryFilter("button"),
    useCategoryFilter(),
    useCustomerFilter(),
    useAccountManagerFilter(),
    useProjectLeaderFilter(),
    useAdjustmentClauseFilter(),
    useFlagsFilter(),
    useCurrencyFilter()
  );

  const {
    initialState: row2InitialState,
    filterFunction: row2FilterFunction,
    filterComponents: row2FilterComponents,
  } = useFilter(
    useFilterOn({
      title: "Group By",
      state: { name: "Category, Country", value: ["category", "country"] },
      modal: <div />,
      name: "groupBy",
      filter: (filter, item) => true,
      fields: fields,
      type: "groupBy",
    }),
    useFilterOn({
      title: "By Date",
      name: "date",
      state: 3,
      filter: (filter, item) => true,
      type: "button",
      options: dates,
      optionsLoading: false,
    })
  );

  const [filterState, setFilterState] = useState({
    ...row1InitialState,
    ...row2InitialState,
  });

  const getDate =
    filterState.date === 1
      ? // Expected Ok
        (item) => item.expectedOk
      : filterState.date === 2
      ? // Real Ok
        (item) => item.ok || item.expectedOk
      : filterState.date === 3
      ? // Settlement
        (item) => {
          const eta = item?.getExpectedSettlementDate();
          return eta == null || (eta.getTime() < startOfToday() && item.status !== "settled") ? startOfToday() : eta;
        }
      : // Settlement - 5
        (item) => {
          const eta = item?.getExpectedSettlementDate();
          const settlement = eta == null || (eta.getTime() < startOfToday() && item.status !== "settled") ? startOfToday() : eta;
          const ret = addDays(settlement, -5);
          return ret;
        };

  const loading = itemsLoading || prices == null || currencies == null;

  let table;
  let columns;
  let data;

  if (!loading) {
    const filtered = items.filter(
      (x) =>
        x.status !== "canceled" &&
        x.sellOrder?.project?.code !== "INT" &&
        row1FilterFunction(filterState, x) &&
        row2FilterFunction(filterState, x)
    );
    const tree = {
      items: buildTree(filtered, [
        ...filterState.groupBy.value.map((f) => ({ id: (x) => x.id, key: (x) => fieldProjectors[f](x) })),
        { id: (x) => x.id, key: (x) => x.sellOrder },
      ]),
    };
    const matrix = mapTree(tree, (node, level) => leaf(node, ars, usd, prices, getDate), branch);
    const rows = flatten(matrix);
    let firstBalance = orderBy(Object.entries(rows[0].values), (x) => x[0]).findIndex((x) => x[0].endsWith("_settled"));
    if (firstBalance === -1)
      firstBalance = orderBy(Object.entries(rows[0].values), (x) => x[0]).findIndex((x) => x[0].endsWith("_unsettled")) - 1;

    columns = [];
    const minDate = min(filtered.map((x) => startOfMonth(getDate(x))));
    const maxDate = max(filtered.map((x) => startOfMonth(getDate(x))));
    for (let d = minDate; d?.getTime() <= maxDate.getTime(); d = addMonths(d, 1))
      if (startOfMonth(d).getTime() === startOfMonth(startOfToday()).getTime()) {
        columns.push({
          key: getColumn(d, "settled").code,
          title: getColumn(d, "settled").name,
          align: "right",
          content: { projectors: (x) => <span>{formatAmount(x.values[getColumn(d, "settled").code])}</span> },
        });
        columns.push({
          key: getColumn(d, "pending").code,
          title: getColumn(d, "pending").name,
          align: "right",
          content: { projectors: (x) => <span>{formatAmount(x.values[getColumn(d, "pending").code])}</span> },
        });
      } else
        columns.push({
          key: getColumn(d, "settled").code,
          title: getColumn(d, "settled").name,
          align: "right",
          content: { projectors: (x) => <span>{formatAmount(x.values[getColumn(d, "settled").code])}</span> },
        });

    const settledIndex = columns.findIndex((x) => x.key.endsWith("_settled"));
    const expenseRow = (title, value, level, leaf) => {
      let entries = [];
      if (typeof value === "number") {
        entries = columns.map((x, i) => (i <= settledIndex ? [x.key, 0] : [x.key, -value]));
      } else entries = value;

      console.log({ entries });

      return {
        level: level,
        isLeaf: leaf,
        values: {
          ...Object.fromEntries(entries),
          _total: sum(entries.map((x) => x[1])),
        },
        key: { id: "_" + title, name: title },
      };
    };

    const buildTotals = (id, title, rows) => {
      if (rows.length === 0) return null;
      const level = rows[0].level;
      const values = columns.map((x) => [x.key, sum(rows.filter((r) => r.level === level).map((r) => r.values[x.key]))]);
      const total = sum(values.map((x) => x[1]));
      return {
        level: level - 1,
        isLeaf: false,
        values: {
          ...Object.fromEntries(values),
          _total: total,
        },
        key: { id, name: title },
      };
    };

    console.log({ columns });

    const buildColumns = (values) =>
      values
        .map((v) =>
          v.month != null
            ? [[v.month, -v.value]]
            : columns.map((c) => ((c.key >= v.from || v.from == null) && (c.key <= v.to || v.to == null) ? [c.key, -v.value] : [c.key, 0]))
        )
        .flat();

    const indent = (level) => "-" + "  ".repeat(level);

    const buildRows = (node, level = 1) => {
      console.log(indent(level) + " buildRows", { node, level });
      const res = [];
      for (const entry of Object.entries(node)) {
        const [key, value] = entry;
        if (typeof value === "number") res.push(expenseRow(key, value, level, true));
        else if (Array.isArray(value)) {
          const split_values = buildColumns(value);
          res.push(expenseRow(key, split_values, level, true));
        } else {
          const rows = buildRows(value, level + 1);
          const totals = buildTotals(key, key, rows);
          if (totals) res.push(totals);
          res.push(...rows);
        }
      }
      return res;
    };

    /*
    const net = Object.fromEntries(
      orderBy(Object.entries(rows[0].values), (x) => x[0])
        .filter((x) => x[0] !== "_total")
        .map((x, i) => [x[0], i <= firstBalance - 1 ? 0 : i === firstBalance ? bank_balance : x[1] - outcomes])
    );

    const acum = Object.fromEntries(
      orderBy(Object.entries(net), (x) => x[0])
        .filter((x) => x[0] !== "_total")
        .map((x, i, arr) => [x[0], sum(arr.map((x) => x[1]).slice(firstBalance, i + 1))])
    );
*/
    data = [
      { ...rows[0], level: rows[0].level + 1, key: { id: "_total", name: "Incomes" } },
      ...rows.slice(1).map((x) => ({ ...x, level: x.level + 1 })),
      ...buildRows(outcomes, 1),
      /*
      {
        level: 1,
        expandable: false,
        values: { ...net, _total: rows[0].values._total - outcomes * columns.length },
        key: { id: "_net", name: "Net" },
      },
      {
        level: 0,
        values: { ...acum, _total: orderBy(Object.entries(acum), (x) => x[0]).slice(-1)[0][1] },
        key: { id: "_acum", name: "Balance" },
      },*/
    ];

    const netRow = buildTotals("_net", "Net", data);
    const balanceRow = { ...netRow, key: { ...netRow.key }, values: { ...netRow.values } };
    const sortedKeys = orderBy(Object.keys(netRow.values));
    const settledKey = sortedKeys[0];

    netRow.values[settledKey] = 0;
    balanceRow.values[settledKey] = bank_balance;
    balanceRow.key.id = "_balance";
    balanceRow.key.name = "Balance";

    let acum = 0;
    for (const k of sortedKeys) {
      const amount = acum + balanceRow.values[k];
      console.log(k, { n: netRow.values[k], b: balanceRow.values[k], acum, amount });
      acum = amount;
      balanceRow.values[k] = amount;
    }

    data.push(netRow);
    data.push(balanceRow);

    table = (
      <div style={{ overflowX: "auto" }}>
        <ScrollableTable
          data={data}
          columns={[
            {
              title: "Item",
              content: {
                projectors: (x) => x.key.name,
              },
            },
            ...columns.map((x) => ({ ...x })),
            { title: "Total", align: "right", content: { projectors: (x) => formatAmount(x.values._total) } },
          ]}
        />
      </div>
    );
  }

  return (
    <Common.Col rows="auto auto 1fr" gap="1em" padding="1em">
      <Common.Row cols="auto 1fr" gap="1em">
        <Title>Future Cashflow</Title>
        <Filter fontSize="0.45em" model={filterState} onChange={setFilterState}>
          <div />
          {row1FilterComponents}
        </Filter>
      </Common.Row>
      <Filter fontSize="0.45em" model={filterState} onChange={setFilterState}>
        <div />
        {row2FilterComponents}
      </Filter>
      {loading ? "Loading..." : table}
    </Common.Col>
  );
}

const Title = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  color: #777;
`;
