import { useEffect, useState } from "react";
import { icons } from "@lainco/react-toolbox";
import styled from "styled-components";

const buildVisibile = (data, expanded, index, level, visible) => {
  if (index >= data.length) return [];
  else if (level < data[index].level) {
    const subtrees = buildVisibile(data, expanded, index, level + 1, visible);
    const sameLevel = buildVisibile(data, expanded, index + subtrees.length, level, visible);
    return [...subtrees, ...sameLevel];
  }

  let result = [visible];
  let i = index + 1;
  let lastParent = index;

  while (i < data.length && data[i].level >= level) {
    let toAdd;

    if (data[i].level === level) {
      toAdd = [visible];
      lastParent = i;
    } else toAdd = buildVisibile(data, expanded, i, level + 1, visible && (expanded[lastParent] ?? true));

    result = [...result, ...toAdd];
    i += toAdd.length;
  }

  return result;
};

export function ScrollableTable({ data, columns }) {
  const [expanded, setExpanded] = useState(data.map((x) => (x.level <= 2 ? true : x.isLeaf ? null : false)));
  const [visible, setVisible] = useState(data.map((x) => (x.level <= 3 ? true : false)));

  useEffect(() => {
    const l = [5, 4, 3, 2, 1, 0]
      .map((level) => ({
        level: level - 1,
        q: data.filter((x) => x.expandable || (x.expandable !== false && x.level <= level) || (x.isLeaf && x.level === level - 1)).length,
      }))
      .find((x) => x.q <= 25).level;

    const _expanded = data.map((x) => (x.expandable || (x.expandable !== false && x.level <= l) ? true : x.isLeaf ? null : false));
    setExpanded(_expanded);
    setVisible(buildVisibile(data, _expanded, 0, 0, true));
  }, [data]);

  const toggleExpanded = (index) => {
    const _expanded = [...expanded.slice(0, index), !expanded[index], ...expanded.slice(index + 1)];
    const _visible = buildVisibile(data, _expanded, 0, 0, true);
    setVisible(_visible);
    setExpanded(_expanded);
  };

  return (
    <Table>
      <thead>
        <HeaderRow>
          {columns.map((col, index) => (
            <HeaderCell key={index} align={col.align}>
              {col.title}
            </HeaderCell>
          ))}
        </HeaderRow>
      </thead>
      <tbody>
        {data.map((x, i) => (
          <BodyRow key={i} visible={visible[i]}>
            {columns.map((col, j) => (
              <BodyCell key={i + "." + j} level={x.level} isLeaf={x.isLeaf} isColHeader={j === 0}>
                <CellContent isColHeader={j === 0} align={col.align}>
                  {j === 0 &&
                    (x.isLeaf ? (
                      <div style={{ width: "1.5em", display: "inline-block" }} />
                    ) : (
                      (x.expandable || (x.expandable == null && x.level > 0)) && (
                        <Expander onClick={(x) => toggleExpanded(i)}>
                          {icons.solid.chevronRight({
                            style: { transform: expanded[i] ? "rotate(90deg)" : "rotate(0deg)", transition: "transform 0.5s ease-in-out" },
                          })}
                        </Expander>
                      )
                    ))}
                  {col.content.projectors(x)}
                </CellContent>
              </BodyCell>
            ))}
          </BodyRow>
        ))}
      </tbody>
    </Table>
  );
}

const defaultStyle = {
  background: "transparent",
  color: "black",
  overflowX: "unset",
  textOverflow: "unset",
  whiteSpace: "unset",
  borderRight: "1px solid #ccc",
  borderBottom: "1px solid #ccc",
};

const headers = {
  0: { background: "#000000", borderRight: "1px solid #222", borderBottom: "1px solid #222", color: "white", fontSize: "1em" },
  1: { background: "#282828", borderRight: "1px solid #000", borderBottom: "1px solid #000", color: "white", fontSize: "0.95em" },
  2: { background: "#383838", borderRight: "1px solid #333", borderBottom: "1px solid #333", color: "white", fontSize: "0.9em" },
  3: { background: "#484848", borderRight: "1px solid #555", borderBottom: "1px solid #555", color: "white", fontSize: "0.85em" },
  4: { background: "#585858", borderRight: "1px solid #777", borderBottom: "1px solid #777", color: "white", fontSize: "0.8em" },
  5: { background: "#686868", borderRight: "1px solid #888", borderBottom: "1px solid #888", color: "white", fontSize: "0.75em" },
  6: { background: "#787878", borderRight: "1px solid #999", borderBottom: "1px solid #999", color: "white", fontSize: "0.7em" },
  7: { background: "#808080", borderRight: "1px solid #aaa", borderBottom: "1px solid #aaa", color: "white", fontSize: "0.66em" },
  8: { background: "#c8c8c8", borderRight: "1px solid #bbb", borderBottom: "1px solid #bbb", color: "black", fontSize: "0.63em" },
  9: { background: "#ddd", borderRight: "1px solid #ccc", borderBottom: "1px solid #ccc", color: "black", fontSize: "0.6em" },
};

const colHeader = {};

const cell = {
  ...defaultStyle,
  borderBottom: "0px solid #ccc",
};

const formatCell = (level, isLeaf, isColHeader) => {
  const { background, color, fontSize, overflowX, textOverflow, whiteSpace, borderRight, borderBottom } = {
    ...defaultStyle,
    ...headers[level],
    ...(isLeaf && !isColHeader ? cell : {}),
    ...(isColHeader ? colHeader : {}),
  };

  return `
    background-color: ${background};
    color: ${color};
    font-size: ${fontSize};
    overflow-x: ${overflowX};
    text-overflow: ${textOverflow};
    white-space: ${whiteSpace};
    border-right: ${borderRight};
    border-bottom: ${borderBottom};
    `;
};

const Table = styled.table`
  font-weight: 400;
`;

const HeaderRow = styled.tr`
  border-top: 1px solid #ccc;
  text-transform: uppercase;
  font-weight: 600;
  color: #777;
  font-size: 0.7em;
`;

const HeaderCell = styled.th`
  padding: 0.5em 0.9em;
  text-align: ${(props) => props.align || "left"};
`;

const CellContent = styled.div`
  display: flex;
  justify-content: ${(props) => (props.align === "right" ? "end" : props.align === "center" ? "center" : "start")};
  margin-left: ${(props) => (props.align === "right" ? "1em" : props.align === "center" ? "0.5em" : "0")};
  margin-right: ${(props) => (props.align === "right" ? "0em" : props.align === "center" ? "0.5em" : "1em")};
  ${(props) =>
    props.isColHeader
      ? `    
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    `
      : ""}
`;

const BodyRow = styled.tr`
  display: ${(props) => (props.visible ? "table-row" : "none")};
  &:nth-child(even) {
    background: #eee;
  }
`;

const BodyCell = styled.td`
  padding: 0.5em 0.9em;
  ${(props) => {
    return formatCell(props.level, props.isLeaf, props.isColHeader);
  }}
`;

const Expander = styled.span`
  display: inline-block;
  padding: 0.2em;
  padding-right: 0.5em;
  cursor: pointer;
`;
