export const bank_balance = 591000;

const fx = 1200;

const onTop = (salary) => salary * 1.01 + 29;
const argentina = (relDep, monotributo, swissMedical) => (relDep * 1.43 + monotributo + swissMedical) / fx;
const conIva = (value) => value / 1.21 / fx;
const sinIva = (value) => value / fx;

export const outcomes = {
  Outcomes: {
    Travel: {
      Travel: 7000,
    },
    SOP: {
      Ariel: onTop(4000) /* On Top */,
      Edu: argentina(399630, 3506165, 220000),
      Lauti: argentina(391306, 1390405, 0),
      Mati: argentina(2409000, 0, 0),
    },
    dev: {
      "Current Team": [{ month: "202503_unsettled", value: onTop(4000 + 5200 + 4800 + 4800 + 4500 + 3500 + 3000 + 4500 + 3500) }],
      POs: [{ from: "202504", value: onTop(2 * 6500) }],
      UX: [{ from: "202504", value: onTop(6000) }],
      Arq: [{ from: "202504", value: onTop(6000) }],
      Test: 3 * onTop(600),
      "Value Streams": {
        "Cell 1": [{ from: "202504", value: onTop(6000 + 5250 + 4000 + 4000) }],
        "Cell 2": [{ from: "202504", value: onTop(6000 + 5250 + 4000 + 4000) }],
        "Cell 3": [{ from: "202504", value: onTop(6000 + 5250 + 4000) }],
        "Cell 4 (Marce)": [{ from: "202504", value: onTop(6000 + 5250 + 4000 + 4000 + 4000 + 4000 * 0.3) }],
        "Junios x 2": [{ from: "202504", value: onTop(2 * 2500) }],
      },
      "R & D": { Gaby: onTop(4000), David: onTop(4000 * 0.7), Boreal: 2000 /* 900 */ },
    },
    "One Time": {
      Recruiting: [{ month: "202504", value: /* POs */ 2 * 6000 + /* UX */ 6000 + /* DEV */ 11 * 4000 }],
      Computadoras: [{ from: "202504", to: "202506", value: 5 * 1500 }],
      "Visa / Nueva Estructura": [
        { month: "202504", value: 5000 },
        { month: "202505", value: 25000 },
      ],
    },
    Indirectos: {
      Eventos: [
        { month: "202506", value: 5000 },
        { month: "202512", value: 7000 },
      ],
      "Licencias/Infra": {
        Argentina: {
          DropBox: 12,
          Wasabi: 9,
          Atlassian: { Normal: 1057, Tuqui: 135 },
          npm: 77,
          github: 10,
          figma: 40,
          docker: 11,
          "Amazon AWS": { S1: 6, S2: 273, S3: 36 },
          "Chat GPT": 40,
          "Google Suite": 243,
          Loom: 60,
        },
        USA: {
          github: 80,
          Kamatera: 500 /* 420 */,
          "Biblioteca de Libros": 16,
          Sonarcloud: 86,
        },
      },
      Directores: 5000 * 3,
      Administración: { Xime: argentina(364000, 2100000, 0) + onTop(500), Vicky: 1200 },
      "Recursos Humanos": onTop(1800) + 130000 / fx /*Swiss Medical */,
      Servicios: {
        Argentina: {
          Carlos: conIva(484000),
          iPlan: conIva(105000),
          "Telecom/Personal": conIva(400000),
          Detrano: conIva(57000),
          Nalu: conIva(76000),
          CESSI: sinIva(84700),
          Ingles: [
            { month: "202503", value: conIva(300000) },
            { from: "202505", value: conIva(1500000) },
          ],
        },
        USA: {
          Oficina: 652,
          Lazo: 500,
          Quickbooks: 121,
          Moringo: 9000 / 12,
        },
      },
      Others: 1000,
    },
    Inversión: {
      "Fer Perla": 2000,
      Lala: 3900,
      Otros: 1000,
    },
  },
};
